.sensors{
    display:flex; 
    flex-direction: column; 
    flex-wrap: wrap; 
    align-items: center;
    justify-content: center;
  }

  .sensor{
    border: solid 1px; 
    background-color: #5da552;
    color: black; 
    display: flex; 
    flex-direction: column;
    height: 200px; 
    border-radius: 5px; 
    padding: 1rem; 
    margin: 1rem; 
    min-width: 4rem; 
    width: fit-content;
    font-size: min(22px, 3vw);
  }

  .sensor > span{
    align-self: center;
    display: flex;
    flex-direction: row;
  }


