form{
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
  align-items: center;
}

form button{
  margin: 1rem 0rem; 
  display: inline-block;
  height: 3rem;
  width: auto;
  text-align: center;
  padding: 1rem; 
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

form button:hover {
  background-color: #3e8e41;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

form input{
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
}

