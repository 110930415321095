.threeCanvas{
  width: 1200px;

}
.changeButton {
  margin: 0; 
  margin-bottom: 1rem;
  height: 4rem;
  width: fit-content;
  align-self: center;
  text-align: center;
  padding: 1rem; 
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.changeButton:hover {
  background-color: #3e8e41;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}
.submits{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.topSub{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
